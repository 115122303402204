<template>
  <div class="big">
    <div id="back">

      <el-image @click="goBack()" style="width: 22px; height: 22px; box-sizing: content-box;vertical-align:middle;margin-left: 24px" :src="require('../../assets/basegreenhouse/btn_return.png')" />
      <el-button  @click="goBack()" style="padding: 0 0 0 10px !important; color: #8D8E98; ">基地检测数据</el-button>
      <el-image @click="goBack()" style="width: 7px; height: 11px; box-sizing: content-box;vertical-align:middle;margin-left: 10px" :src="require('../../assets/basegreenhouse/triangle_right.png')" />
      <el-button style="font-size: 14px;color: #333333;margin-left: 10px;padding: 0">{{ name }}</el-button>
    </div>
    <div style="height: 160px;width: auto;margin-top: 24px;  box-sizing: border-box;">
      <div style="height: 160px;background: white; border-radius:6px;margin-left: 260px;margin-right: 20px">
        <div style="padding-top: 15px">
          <el-image  style=" margin-left: 21px;box-sizing: content-box;vertical-align:middle;" :src="require('../../assets/basegreenhouse/base.png')" />
          <el-select style="width:300px;border: none;margin-left: 18px;" v-model="bid" filterable collapse-tags   placeholder="">
            <el-option
                    v-for="item in jidiName"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"/>

          </el-select>
          <hr style="margin: 16px 0 0 0;background: #E5E5E5;height:0.8px; border:none;"/>
          <div style="margin: 22px 0 0 21px;" >
            <div style="width: 217px;float: left;">
              <el-image class="hezuo_img" :src="require('../../assets/basegreenhouse/ic_area.png')" />
              <p><span class="hezuo_mian">{{allAreas}}</span><span style="font-size: 14px">㎡</span></p>
              <p class="hezuo_biao">基地种植面积</p>
            </div>
            <div style="width: 217px;float: left;">
              <el-image class="hezuo_img" :src="require('../../assets/basegreenhouse/ic_number.png')" />
              <p><span class="hezuo_mian">{{detailList.length}}</span><span style="font-size: 14px">个</span></p>
              <p class="hezuo_biao" >大棚数量</p>
            </div>
            <div style="min-width:750px;float: left;">
              <el-image class="hezuo_img" :src="require('../../assets/basegreenhouse/ic_site.png')" />
              <p class="hezuo_mian" style="font-size: 16px;line-height:25px;">{{address}}</p>
              <p class="hezuo_biao" >基地位置</p>
            </div>

          </div>
        </div>
      </div>

    </div>
    <div  class="box" ref="listContainer">
      <div class="b_div_can" v-for="(item,idx) in detailList" :key="idx">
        <div class="b_div_son_can">
          <div style="text-align: left;height: 40px;">
            <span style="width: 5px;height: 26px; background: #17BE59;border-radius: 2.5px;display: inline-block;float:left;margin: 18px 8px 0 15px;"></span>
            <span style="display: inline-block;margin-top: 20px;">{{ item.name }}</span>
            <el-button class="xiangqing" @click="toDeatil(item.id,item.name,item.crop,item.area,item.admin)">大棚详情</el-button>
          </div>
          <div style="width: 330px;height: 155px;background: #F7F7F7;border-radius: 6px;display: flex;margin: 22px auto;">
            <el-row :span="24" style="margin-top: 15px">
              <el-col :span="13" class="right-center-box">
                <el-col :span="10" class="center-box-img"><img src="../../assets/basegreenhouse/ic_module_weather.png" alt="" width="36"
                                                               height="36"></el-col>
                <el-col :span="14" style="text-align: left;">
                  <el-col class="center-box-size-title">空气温度</el-col>
                  <el-col class="center-box-size-con">{{ item.zdata ? item.zdata.temper :'--' }}℃</el-col>
                </el-col>
              </el-col>

              <el-col :span="11" class="right-center-box">
                <el-col :span="10" class="center-box-img" ><img src="../../assets/basegreenhouse/ic_module_soil.png" alt="" width="33"
                                                                height="36"></el-col>
                <el-col :span="14" style="text-align: left;">
                  <el-col class="center-box-size-title">土壤湿度</el-col>
                  <el-col class="center-box-size-con">{{ item.zdata ? item.zdata.landHumid :'--' }}%</el-col>
                </el-col>
              </el-col>

              <el-col :span="13" class="right-center-box">
                <el-col :span="10" class="center-box-img"  style="margin-top: -5px"><img src="../../assets/basegreenhouse/ic_module_ppm.png" alt="" width="36"
                                                                                         height="26"></el-col>
                <el-col :span="14" style="text-align: left;">
                  <el-col class="center-box-size-title">二氧化碳浓度</el-col>
                  <el-col class="center-box-size-con">{{ item.zdata ? item.zdata.co2 :'--' }}ppm</el-col>
                </el-col>
              </el-col>

              <el-col :span="11" class="right-center-box">
                <el-col :span="10" class="center-box-img"><img src="../../assets/basegreenhouse/ic_module_light.png" alt="" width="36"
                                                               height="37"></el-col>
                <el-col :span="14" style="text-align: left;">
                  <el-col class="center-box-size-title">光照度</el-col>
                  <el-col class="center-box-size-con">{{ item.zdata ? item.zdata.light :'--' }}klux</el-col>
                </el-col>
              </el-col>
            </el-row>
          </div>


        </div>
      </div>
      <div class="b_div_can" v-for="(item,two) in paddingLength" :key="(two+1)*100">

      </div>

    </div>
    <div style="width: auto;margin-left: 260px;text-align: center;margin-top: 20px">
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[8, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>
<!--//高德地图-->

<script>
export default {
  name: "grennhouse",
  data(){
    return {
      pageIndex: 1,
      pageSize: 8,
      totalPage: 0,
      detailList: [],
      bid:0,
      name:'',
      locationCity:"" ,    //给渲染层定义一个初始值
      value:'',
      paddingLength: 0,
      jidiName:[],
      allAreas:'',//基地面积
      address:'',//基地位置
    }
  },
  mounted() {
    console.log('jiushou',this.$route.query.names)
    if(this.$route.query.id){
      this.bid =parseInt(this.$route.query.id)
      this.name=this.$route.query.name
    }else {
      this.bid = parseInt(this.$route.query.bid)
      this.name = this.$route.query.names
    }
    // this.getDate(this.bid)
    this.getDataNameList();
    this.$bas.$emit("suiji",'/qiheIndex')

  },
  methods: {
    //返回上一级
    goBack(){
      this.$router.push({
        name: "qiheIndex",
        query: {

        }
      })
    },
    getDataNameList(){
      this.$api.get('base/list', {}, result => {
        this.jidiName = result.rows

      })
    },
    getDate(bid){


      this.$api.get('gHouse/getByBidList', {bid:bid,pageNum:this.pageIndex,pageSize:this.pageSize}, res => {
        this.detailList = res.list.rows
        this.allAreas=res.allAreas
        this.address=res.address

        this.totalPage = res.list.total
        let self = this
        this.reCalcPaddingLength()
        window.onresize = function () {
          self.reCalcPaddingLength()
        }

      })
    },// 解决space-between布局的问题，添加div填充空位
    reCalcPaddingLength () {
      let outerLength = this.$refs.listContainer.clientWidth // 外面div的宽度
      let listLen = this.detailList.length // 子元素个数，自行调整
      let lineCapacity = Math.floor(outerLength / 350) // 计算每一行的容量
      this.paddingLength = listLen % lineCapacity === 0 ? 0 : (lineCapacity - listLen % lineCapacity) // 得出需要添加的空块
      console.log("paddingLength------"+this.paddingLength)
    },
    toDeatil(id,title,crop,area,admin) {
      console.log(id,title,'路由传参')
      // if(this.$getPermission('/pc/data/getListEqui')){
        this.$router.push({
          name: "qiheGrennhouseDetails",
          query: {
            bid:this.bid,
            id,
            title,
            name:this.name,
            area,
            crop,
            admin,
            path:'/grennhouse'
          }
        })
      // }else {
      //   this.$message({
      //     showClose: true,
      //     message: '暂无查看权限',
      //     type: 'warning'
      //   });
      // }

    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDate(this.bid)
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDate(this.bid)
    },
  },
  watch:{
      'bid':{
        handler(newValue,oldValue){
          this.pageIndex=1
          this.bid=newValue
          this.getDate(this.bid)
        }
      }
  }
}
</script>


<style scoped lang="scss">
  .big{
    width: auto;

  }
#back{
  width: auto;
  height: 54px;
  margin-left: 240px;
  background: #F9FCFD;
  line-height: 54px;
  box-sizing: border-box;

}
#back .el-button {
  background: none;
  border: none;
  color: #8D8E98;
  //margin-top: 10px;
}
  .box {
    height: 520px;
    margin: 0 20px 0 260px;
    display: flex;
    /*border: 1px solid #ccc;*/
    width: calc(100% - 280px);
    justify-content: space-between;
    flex-wrap: wrap;

  }

  .b_div_can {
    width: 400px;
    height: 250px;
    float: left;
    margin-top: 10px ;
    cursor: pointer;
  }

  .b_div_son_can {
    width: 400px;
    height: 250px;
    /*border: 1px solid #333;*/
    background: #FFFFFF;
    text-align: center;
    border-radius: 6px;
  }

  .haad-title {
    height: 59px;
    line-height: 59px;
    font-size: 20px;
    color: #009400;
    border-bottom: 1px solid #E8E8E8;
  }

  .center-box-img {
    line-height: 68px;
    text-align: center
  }

  .center-box-size-title {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;

  }

  .center-box-size-con {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0090FF;

  }

  p{
    padding: 0;
    margin: 0;
  }
  .hezuo_img{
    float:left;
    width: 44px;
    height: 44px;
    margin-right: 14px;
  }
  .hezuo_mian{
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }
  .hezuo_biao{
    font-size: 14px;
    font-weight: 400;
    color: #8D8E98;
  }
  /deep/.sousuo .el-select{
    background: none !important;
    border: none !important;
  }

  /*.el-input.el-input--suffix {*/
  /*// 修改背景颜色、字体颜色、边框、宽高*/
  ::v-deep.sousuo .el-select .el-input__inner {
    background: none !important;
    border: none  !important;
    color: #333333  !important;

  }
  /deep/.el-input__inner {
    background: none !important;
    border: none  !important;
    color: #333333  !important;

  }
  /*右侧图标样式  \e790下箭头图标*/
  /deep/.el-select .el-input .el-select__caret.el-input__icon::before {
    content: "\e790";
    color: #134453;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  /*如果不设置图标下拉时是反的*/
  /deep/.el-select .el-input .el-select__caret {
    transform: rotateZ(0deg);
  }
  .xiangqing{
    width: 86px;
    height: 33px;
    background: url("../../assets/basegreenhouse/btn_green_small.png") no-repeat;
    border: none;
    padding: 0;
    float: right;
    margin: 15px 17px 0 10px;
  }
  .xiangqing:hover{
    color: #166D66;
  }
</style>
